import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    PointPara: '⟶',
    ParaOneLeft:
      'Enterprise Spend Management technologies such as Raindrop deliver increased efficiencies, reduced cycle times, enhanced internal and external collaboration, materials consumption visibility, and higher transparency across all contractual obligations. Raindrop’s',
    ParaOneAnchor: 'Supplier Management',
    ParaOneRight:
      'module helps effectively manage your suppliers and move your supplier management from being reactive to proactive.',
    ShiftUrlParaOne: 'https://raindrop.com/solutions/SupplierManagement',
    ParaTwo:
      'In general, and especially in the Procurement and Supply Chain space, technology is transforming consistently, enabling Chief Procurement Officers new ways to solve their spend management challenges. Like most organizations, you may have suppliers’ spend data fragmented across various point solution systems. This creates the challenge of rapidly accessing clean holistic information to plan your future capital and operational expenses. This is where Raindrop’s Supplier Relationship Management (SRM) module comes in, which provides insights into how your suppliers are performing on each of the above key topics. From onboarding to continuous improvement, Raindrop makes it easy to understand the status of your suppliers and extract the most value from each relationship and make more informed and data-driven decisions.',
    ParaThree:
      'While companies have made radical changes in the way they use technology to manage their supply chains over the past years, It is crucial to understand the top 5 common global supply chain risks',
    HeadingOne: '1. Global economic instability',
    ParaFourBefor:
      'Change is the only constant in life.  Challenges in today’s global economy impact every company.  Combined with',
    ParaFourAnchor: 'political instability',
    ParaFourAfter:
      'these factors can have an uncontrollable impact on global supply chains. Considering the wide-scale impact, industry experts and CPOs advise that –',
    AnchorLink:
      'https://in.video.search.yahoo.com/search/video;_ylt=AwrKBILrJS9jYTYyCE.7HAx.;_ylu=Y29sbwNzZzMEcG9zAzEEdnRpZAMEc2VjA3Nj?p=supply+chain+and+global+politics&type=E211IN714G0&ei=UTF-8&fr=mcafee&turl=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOVP.1iWQsSBNYaWpjK-wIFCm-wHgFo%26pid%3DApi%26w%3D296%26h%3D156%26c%3D7%26p%3D0&rurl=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D1KtTAb9Tl6E&tit=Why+Global+Supply+Chains+May+Never+Be+the+Same+%7C+A+WSJ+Documentary&pos=1&vid=88e54a5a78e59ebce284967943f74ec5&sigr=i6OM2KRcPx0g&sigt=V.941obY.JZd&sigi=AP2BG4PT.LXA',
    HeadingTwo: '“Hope for the best, but plan for the worst”.',
    ParaFiveLeft:
      'With political and economic instability impacting supply chains across the globe, the need to find new suppliers (global or local), as well as measure and manage your existing suppliers, in a more timely and effective manner is one of the top challenges for supply chains.',
    ParaFiveAnchor: 'Raindrop’s e-Sourcing module',
    ParaFiveRight:
      'is a highly intuitive and easy-to-use solution, designed to take your business out to market quickly and efficiently, to develop new supply sources, or to refine your existing supply base. Gain the desired results in a compressed amount of time with Raindrop’s streamlined and digitized process. Raindrop helps to take your request out to a global or domestic supplier base in just a few clicks. Define your scope, invite vetted bidders, and the intuitive platform will run and manage the entire bidding process, resulting in competitive bids and analysis from your qualified suppliers for the desired goods and services. Gain the desired results in a compressed amount of time with Raindrop’s streamlined and digitized process.',
    ShiftUrlParaFive: 'https://raindrop.com/solutions/SourcingEvents',
    HeadingThree: '2. Supplier consistency',
    ParaSix:
      'Procurement departments must ensure supplier consistency by creating a diverse supplier network. Supplier consistency can be disturbed due to a disaster, pandemic, natural calamity, or war. In such a case, raw and reclaimed materials are subject to disruption risk. Raindrop supports supplier self-service access allowing them to create and maintain their own profiles, which helps in reducing customer burden on data upkeeping. With Raindrop, suppliers can self-register and maintain all necessary documents, certifications, and all related business data, such as certificates of insurance, code of conduct acceptance, as well as industry-specific compliance issues such as human trafficking, conflict minerals, etc. Save valuable time by automating supplier tasks.',
    HeadingFour: '3. Data integrity and authentication',
    ParaSeven:
      'Data integrity is the central pillar of creating holistically valuable outcomes. In most cases, corporate spend data is derived or provided from multiple source systems, so ensuring your information is correct is the first step of the process. Integration to these various data sources helps to create a multidimensional view of internal and external spend. Raindrop helps to provide a holistic view across the entire corporate spend. Spend and cost information can be combined with external data, quantitative or qualitative – such as geographical assessments, risk analyses, alternative supply sources, and financial statistics. This type of 360-degree view enables companies to make better decisions, increasing the resiliency of their supplier spend strategy.',
    HeadingFive: '4. Transparent integration, and modification',
    ParaEight:
      'Digitization and technology solutions make it easy to bring security, reliability, and governance to transactions and provide the foundation for secure data movement within the enterprise. Raindrop’s Supplier Management module provides a real-time and transparent flow of information. Raindrop allows you to understand supplier-related sourcing activities, contracts, performance, risk, and compliance. You get all your answers, instantly with a few clicks about – “Who is buying?”, “Which is the selected supplier?”, “How much are you spending?”, and “When does the contract expire?”',
    HeadingSix: '5. Cyber attacks',
    ParaNine:
      'Cyber-attacks have become a predominant risk in modern supply chain management. Someone with malicious intent could bring down entire supply chain networks and force freight rates to skyrocket. It is important to choose vendors with a proven record of maintaining stringent cybersecurity protocols for secure supply chain systems. Raindrop’s Supplier Management module provides a real-time and transparent flow of information between you and your suppliers.',
    HeadingSeven: 'Trust Raindrop to mitigate supply chain risks',
    ParaTen:
      'Raindrop’s AI-powered supplier management module is simple, intuitive, and rapid to access. It helps to take your request out to a global or domestic supplier base in just a few clicks and helps you take the best decisions at all times. Raindrop’s technology-driven framework gives quick and accurate insights on the financial and contractual performance status of your suppliers, to extract the most value from each relationship.',
  },
]
