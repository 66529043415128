import React from 'react'
import { AppResourcesEmailBanner } from './AppResourcesEmailBanner'
import Top5SupplyChainconcernsBannerData from '../Data/Top5SupplyChainconcernsBannerData.json'
import styled from 'styled-components'

const AppTop5SupplyChainconcernsBannerWapper = styled.div`
  .RetailIndustrySection {
    display: flex;
    justify-content: space-between;
    padding: 130px 50px 50px 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 80px 15px 10px 15px;
    }
  }
  .RetailIndustryImageSection {
    @media (max-width: 1090px) {
      margin: auto;
      padding-top: 30px;
    }
  }
  .RetailIndustryHeading {
    max-width: 940px;
    font-size: 2.5rem;
    line-height: 1.30435em;
    padding: 0px 30px 0px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
      max-width: unset;
    }
  }
  .RetailIndustryPara {
    max-width: 850px;
    padding: 15px 65px 15px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
      max-width: unset;
    }
  }
  .DateRetailIndustryPara {
    max-width: 850px;
    color: #34a1d5;
    font-size: 19px;
    padding: 15px 35px 0px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
    }
  }
  .RetailIndustryBannerImage {
    width: 100%;
    height: auto;
    box-shadow: rgb(160 161 163 / 88%) 0px 3px 7px 0px;
    border-radius: 18px;
  }
  .RetailIndustryAnchor {
    color: #34a1d5;
    font-size: 20px;
    font-weight: 700;
    padding-right: 8px;
    padding-left: 8px;
    text-decoration: none;
  }
  .RetailIndustryAnchor:hover {
    color: #047cc2;
    text-decoration: underline;
  }
  .DisplayNone {
    display: none;
  }
`

export const AppTop5SupplyChainconcernsBanner = () => {
  return (
    <AppTop5SupplyChainconcernsBannerWapper>
      {Top5SupplyChainconcernsBannerData.map((data, index) => {
        return (
          <div key={index}>
            <AppResourcesEmailBanner
              Heading={data.Title}
              DatePara={data.DatePara}
              ImagePath={data.ImagePath}
              AltText={data.AltText}
              LeadParaLeft={data.LeadParaLeft}
              LeadAnchorPara={data.LeadAnchorPara}
              LeadParaRight={data.LeadParaRight}
              ShiftUrlHref={data.ShiftUrlHref}
              achorPara="RetailIndustryAnchor"
              BannerHeading="RetailIndustryHeading"
              LeadParaStyle="RetailIndustryPara"
              BannerPara="DisplayNone"
              DateBannerPara="DateRetailIndustryPara"
              BannerImageSection="RetailIndustryImageSection"
              BannerImage="RetailIndustryBannerImage"
              BannerSection="RetailIndustrySection"
            />
          </div>
        )
      })}
    </AppTop5SupplyChainconcernsBannerWapper>
  )
}
