import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import Top5SupplyChainconcernsPendingData from '../Data/Top5SupplyChainconcernsPendingData'
import { ReUsePtagwithAnchorTag } from './ReUsePtagwithAnchorTag'
// import { ReUsePtagwithAnchorTag } from './ReUsePtagwithAnchorTag'
// import { ReUsePtagwithThreeAnchorTag } from './ReUsePtagwithThreeAnchorTag'

const AppTop5SupplyChainconcernsRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .RetailIndustryContentSectionEmail {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .RetailIndustryHeadingEmail {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .RetailIndustryLeadParaStyleOne {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .RetailIndustryLeadParaStyle {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .achorPara {
    color: #34a1d5;
    font-size: 20px;
    font-weight: 700;
    padding-right: 8px;
    padding-left: 8px;
    text-decoration: none;
  }
  .achorPara:hover {
    color: #047cc2;
    text-decoration: underline;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .RetailIndustryParaSubPointStyle {
    color: #047cc2;
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    font-size: 22px;
    font-weight: bold;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .ItalyPara {
    display: flex;
    color: #071741;
    max-width: 1150px;
    font-size: 1.2rem;
    line-height: 1.4;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
      font-size: 16px;
    }
  }
  .BlogsimageOne {
    width: 90%;
    display: flex;
    padding: 34px 4px 34px 54px;
    /* float: right; */
    @media (max-width: 1090px) {
      float: unset;
      width: 100%;
    }
  }
  .SupplyChainParaStyleOne {
    padding: 0px 15px 0px 45px;
  }
  .RetailIndustryHeadingFiveEmail {
    padding: 15px 15px 15px 45px;
  }
  .SupplyChainHeadingTwo {
    font-weight: bolder;
    font-style: italic;
    font-size: 24px;
    padding: 15px 15px 15px 165px;
  }
  .ParaStyleMultiple {
    padding: 0px 15px 0px 45px;
  }

  .CFOsPriorityParaStyle {
    padding: 15px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .CFOsPriorityachorPara {
    color: #34a1d5;
    font-size: 20px;
    font-weight: 700;
    padding-right: 8px;
    padding-left: 120px;
    -webkit-text-decoration: none;
    text-decoration: none;
  }

  .RFPProcessLeadParaSubPointStyle {
    color: #047cc2;
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    font-size: 22px;
    font-weight: bold;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }

  .RecessionParaStyleOne {
    padding: 0px 15px 15px 45px;
  }

  .BlogsimageTwo {
    width: 90%;
    display: flex;
    padding: 54px 12px 54px 50px;
    /* float: right; */
    @media (max-width: 1090px) {
      float: unset;
      width: 100%;
    }
  }
`

export const AppTop5SupplyChainconcernsRest = () => {
  return (
    <AppTop5SupplyChainconcernsRestWapper>
      <div>
        {Top5SupplyChainconcernsPendingData.map((data, index) => {
          return (
            <div className="MainDigitizationNeedsSection" key={index}>
              <div className="RetailIndustryContentSectionEmail">
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaOneLeft}
                  anchorPara={data.ParaOneAnchor}
                  ParaRight={data.ParaOneRight}
                  href={data.ShiftUrlParaOne}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="SupplyChainParaStyleOne"
                />
                <ReUsePtag para={data.ParaTwo} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThree} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingOne} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaFourBefor}
                  anchorPara={data.ParaFourAnchor}
                  ParaRight={data.ParaFourAfter}
                  href={data.AnchorLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RecessionParaStyleOne"
                />
                <ReUseHTwotag Heading={data.HeadingTwo} HeadingStyle="SupplyChainHeadingTwo" />
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaFiveLeft}
                  anchorPara={data.ParaFiveAnchor}
                  ParaRight={data.ParaFiveRight}
                  href={data.ShiftUrlParaFive}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="SupplyChainParaStyleOne"
                />
                <ReUseHTwotag Heading={data.HeadingThree} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtag para={data.ParaSix} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingFour} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtag para={data.ParaSeven} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingFive} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtag para={data.ParaEight} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingSix} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtag para={data.ParaNine} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingSeven} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtag para={data.ParaTen} paraStyle="ParaStyleMultiple" />
              </div>
            </div>
          )
        })}
      </div>
    </AppTop5SupplyChainconcernsRestWapper>
  )
}
